/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";

import { Grid, Cell } from "@components/Grid";

interface ComponentProps {
  heading: string;
}

const SectionHeading = ({ heading }: ComponentProps) => (
  <Grid>
    <Cell gridColumn={["span 12", null, "3 / span 6"]}>
      <hr
        sx={{
          width: "100%",
          height: "1px",
          bg: "lightGreen",
          border: "none",
          mb: rem(10),
        }}
      />
    </Cell>
    <Cell gridColumn={["span 12", null, "3 / span 6"]}>
      <div>
        <h1
          sx={{
            fontSize: rem(45),
          }}
        >
          {heading}
        </h1>
      </div>
    </Cell>
  </Grid>
);

export default SectionHeading;
