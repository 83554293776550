/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import SectionHeadingBlock from "@src/components/SectionHeadingBlock";
import Testimonial from "@src/components/Testimonial";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import InternalLink from "@src/components/InternalLink";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content pageTitle="About Bernard Caleo" location={location}>
    <div>
      <Container>
        <SectionHeadingBlock heading="Comics">
          <div>
            <Container>
              <ImageTextBlock
                body={
                  <div>
                    <p>
                      Bernard has been a comic book person since first
                      discovering Tintin at the Northcote Library in 1973. He
                      made his first comic, Yell Olé! in 1990 with his friend
                      Tolley, and he has never stopped. He believes that comics
                      are the oldest, the difficultest and the funnest artform
                      that humans have come up with. So far.
                    </p>
                    <p>
                      In 2021 he began his PhD in the School of Culture and
                      Communication at the University of Melbourne, researching
                      ‘the production of place in Australian comic books’.
                    </p>
                  </div>
                }
              />
              <ImageTextBlock
                alt="Tango eight, love and food"
                src="/images/tango.jpeg"
                body={
                  <div>
                    <h2>Tango</h2>

                    <p>
                      Bernard created Cardigan Comics in 1997 to publish the
                      ‘giant Australian romance comics anthology’ Tango, of
                      which nine issues appeared between 1997 and 2009. Across
                      the series, hundreds of stories by hundreds of stablished
                      and emerging cartoonists appeared. A selection of pieces
                      from issues one to eight, The Tango Collection, was
                      published by Allen and Unwin in 2009.
                    </p>

                    <a
                      href="https://www.allenandunwin.com/browse/books/fiction/graphic-novels/The-Tango-Collection-Edited-by-Bernard-Caleo-9781742371436"
                      target="_blank"
                    >
                      Buy the Tango Collection
                    </a>
                  </div>
                }
              />

              <ImageTextBlock
                body={
                  <div>
                    <h2>Twelve Panels Press</h2>

                    <p>
                      Bernard is a partner in the graphic novel publishing
                      enterprise{" "}
                      <a
                        href="https://www.twelvepanelspress.com/"
                        target="_blank"
                      >
                        Twelve Panels Press
                      </a>
                      . Bernard, Elizabeth MacFarlane and Erica Wagner created
                      the imprint in 2015 in order to publish literate graphic
                      novels set in Australia.
                    </p>
                    <p>
                      In 2015 Twelve Panels Press published the English-language
                      translation of German graphic novel The Salty River, Jan
                      Bauer’s autobiographical account of his bushwalk along the
                      Northern Territory’s Larapinta Trail.
                    </p>
                    <p>
                      2021 sees the publication of Safdar Ahmed’s Still Alive:
                      notes from Australia’s immigration detention system. In a
                      shorter online form, under the title Villawood, this
                      graphic novel won a Walkley Award in 2015 for its
                      journalistic presentation of the lives of refugee seekers
                      in detention in Australia.
                    </p>
                    <a
                      href="https://www.twelvepanelspress.com/"
                      target="_blank"
                    >
                      {" "}
                      Visit Twelve Panels Press
                    </a>
                  </div>
                }
              />
              <ImageTextBlock
                body={
                  <div>
                    <img
                      src="images/ledger-awards.jpg"
                      alt="ledger awards"
                    ></img>
                    <h2>The Ledger Awards</h2>

                    <p>
                      In 2016 Bernard was awarded a Platinum Ledger Award for
                      his contributions to Australian Comics Culture. The annual
                      Ledger Awards recognise excellence in the field of
                      Australian comic books and graphic novels.
                    </p>
                    <p>
                      Since their re-launch in 2014, Bernard has hosted the
                      awards night, in Melbourne, in Sydney, and (in Covid)
                      online.
                    </p>

                    <p>
                      <a href="http://ledgerawards.org/" target="_blank">
                        {" "}
                        Visit the Ledger Awards
                      </a>{" "}
                      to have a look at past award winners and at the diversity
                      and dynamism of Australian comic book culture.
                    </p>
                  </div>
                }
              />
              <ImageTextBlock
                alt="devil collects, goat man"
                src="/images/devil-collects.jpeg"
                body={
                  <div>
                    <h2>The Devil Collects</h2>

                    <p>
                      The Devil Collects is a comics project which Bernard is
                      producing as part of the research for his PhD in the
                      School of Culture and Communication at the University of
                      Melbourne. It is a story which he has been researching and
                      developing with his friend, historian Alex McDermott. The
                      Devil Collects spills the beans behind the decline and
                      fall of Marvellous Melbourne in the late 1880s.
                    </p>

                    <a
                      href="https://www.instagram.com/bernard.caleo/?hl=en"
                      target="_blank"
                    >
                      See work-in-progress drawings for The Devil Collects
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Book Illustrations: Bernard produces pictures for books.">
          <div>
            <Container>
              <ImageTextBlock
                alt="Bernard sitting on table in front of sketches with hands up"
                src="/images/nobel-prize.jpeg"
                body={
                  <div>
                    <p>
                      Bernard worked with writer Lorna Hendry and produced the
                      illustrations for this chapter book written for 9–12 year
                      olds. How to Win a Nobel Prize has been translated into
                      may languages world-wide (Polish, Chinese, Turkish,
                      Japanese, Hungarian) and shortlisted for literary awards
                      including the Western Australia Premiers Literary Awards
                      2019.
                    </p>

                    <a
                      href="https://www.blackincbooks.com.au/books/how-win-nobel-prize"
                      target="_blank"
                    >
                      Order a copy of How To Win a Nobel Prize
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Drawings">
          <div>
            <Container>
              <ImageTextBlock
                body={
                  <div>
                    <p>
                      Forever drawing, Bernard posts heartfelt portraits, wry
                      observations and terrible puns.
                    </p>

                    <a href="https://bernardcaleo.tumblr.com/" target="_blank">
                      See Bernard’s cartoony drawings
                    </a>
                    <br />
                    <br />

                    <a
                      href="https://www.facebook.com/bernard.caleo"
                      target="_blank"
                    >
                      See Bernard’s literary drawings
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Documentary film">
          <div>
            <Container>
              <ImageTextBlock
                alt="graphic-novels-melbourne cover"
                src="/images/graphic-novels-melbourne.jpg"
                body={
                  <div>
                    <p>
                      Bernard collaborated with filmmaker Daniel Hayward to
                      write, direct and produce a feature documentary about
                      Melbourne comics culture, Graphic Novels! Melbourne!,
                      which they then toured around Europe. The film premiered
                      in a laneway in Carlton and screened at the world’s
                      greatest comics festival in Angoulême in France in 2013.
                    </p>

                    <a
                      href="https://shift72c-40.akamaized.net/0f94e63d-9770-44f8-8ddc-a237131079c1/graphic_novels_melbourne_trailer_kb.mp4"
                      target="_blank"
                    >
                      View the trailer for Graphic Novels! Melbourne!
                    </a>
                    <br />
                    <br />
                    <a
                      href="https://www.ozflix.tv/#!/browse/film/2717/graphic-novels-melbourne"
                      target="_blank"
                    >
                      Rent Graphic Novels! Melbourne! on OzFlix
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Theatre">
          <div>
            <Container>
              <ImageTextBlock
                alt="graphic-novels-melbourne cover"
                src="/images/theatre.jpeg"
                body={
                  <div>
                    <p>
                      Bernard has been performing in theatre since debuting as
                      the Big Bad Wolf wearing his dad’s moccasins in 1974. In
                      the intervening years he has performed in plays by William
                      Shakespeare, Eugene Ionesco, Harold Pinter, and Kurt
                      Vonnegut. With Bruce Woolley, he devised and performed the
                      two-man superhero show Miracleman , and for the CSIRO he
                      wrote and performed an adaptation of scientist Michael
                      Faraday’s 1848 Royal Society lectures as the one-man show
                      Faraday’s Candle.
                    </p>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Radio comics">
          <div>
            <Container>
              <ImageTextBlock
                body={
                  <div>
                    <p>
                      Bernard appears monthly on Richard Watts’ weekly radio
                      show ‘Smart Arts’ (Thursdays 9am–12pm) on 3RRR FM with a
                      segment called ‘Drawn Out’ presenting local and
                      international comics news and reviews.
                    </p>
                    <a
                      href="https://www.rrr.org.au/explore/programs/smartarts"
                      target="_blank"
                    >
                      Tune in to Smart Arts
                    </a>
                    <p>
                      <Link to="/contact">Contact Bernard</Link> if you have a
                      comic you’d like considered for an on-air review
                    </p>

                    <a href="https://twitter.com/bernardcaleo" target="_blank">
                      Follow Bernard’s twitters about comics
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
        <SectionHeadingBlock heading="Resources">
          <div>
            <Container>
              <ImageTextBlock
                body={
                  <div>
                    <a
                      href="https://www.dropbox.com/s/zzoowwedhlet7qn/TZ1_2563-1.jpg?dl=0"
                      target="_blank"
                    >
                      Hi-res portrait of Bernard
                    </a>

                    <br />
                    <br />
                    <a
                      href="https://www.dropbox.com/s/1tsne7rfy3bswjc/2021%20Bernard%20Caleo%202%20page%20CV%20.pdf?dl=0"
                      target="_blank"
                    >
                      Bernard's resume
                    </a>
                  </div>
                }
              />
            </Container>
          </div>
        </SectionHeadingBlock>
      </Container>
    </div>
  </Content>
);
