/** @jsx jsx */
import { jsx } from "theme-ui";
import SectionHeading from "@components/SectionHeading";
interface ComponentProps {
  heading: string;
  children: React.ReactChild;
}

const SectionHeadingBlock = ({ heading, children }: ComponentProps) => (
  <div>
    <SectionHeading heading={heading} />
    {children}
  </div>
);

export default SectionHeadingBlock;
